<script>
import api from '@/command/api'
import DetailFormGroup from '@/components/DetailFormGroup'
import apiTool from '@/command/apiTool'
import { getAction, postAction, putAction } from '@/command/netTool'
import CardIconLabelLocal from '@/components/CardIconLabelLocal'
import moment from 'moment'
import { Button, DatePicker } from 'ant-design-vue'
import FormUploadSku from '@/components/FormView/FormUploadSku'
import Ticket from '@/views/com/Ticket'
import Naves from '@/views/com/Naves'
import {
  thirdTypeDataList,
  daysBeforeList,
  daysAfterList,
  timeAfterList,
  ticketRefundType,
  ticketRefundRatioList,
} from '@/utils/textFile'

export default {
  name: 'scenic-areas-ticket-detail',
  data() {
    return {
      ...api.command.getState(),
      activeKey: 0,
      labelGroup: [],
      productComboName: [],
      detail: {
        qrShowMode: '0',
        hxMode: '0',
        hxCount: 1,
        realNameFlag: '1',
        bannerUrl: '',
        comboFlag: '0',
        userInfoCount: '0',
        purchaseRestrictionType: '0',
        ticketRefundList: [
          {
            validDay: '1',
            validDate: '6',
            refundType: '0',
            refundExplain: 0,
          },
        ],
        skuList: [
          {
            skuImgUrl: '',
            skuName: null,
            originalPrice: null,
            salePrice: null,
            jsPrice: null,
            allStock: null,
            sort: null,
          },
        ],
        canBuy: '1',
        refundExplain: '0',
        recommend: '0',
        thirdTicket: '0',
        changeTicket: '0',
        joinFx: '0',
        hxExpireType: '1',
        thirdTypeName: '',
        hxStartTime: '',
        hxEndTime: '',
        preDay: '',
        refundRate: '',
        saleCount: '',
        readCount: '',
        buyCount: 0,
        useAfterDay: '',
        useAfterHour: '',
        payType: '0',
        unSend: '0',
        preDay: 0,
        smsContent: '',
        preTime: '',
        hxAfterDay: 0,
        refundWay: '0',
        refundType: '0',
      },
      isShowSnapshot: false,
      storeData: [],
      typeSearchData: [],
      thirdTypeSearchData: [],
      classifyList: [],
      productInfo: {},
      returnSet: [],
    }
  },
  watch: {
    'detail.linkShopId'(a, b) {
      if (a) {
        this.getBaseDataInit(a)
        this.getMainBody(a)
      }
    },
    // 'detail.thirdPlatform'(value) {
    //   this.detail.thirdProductId = ''
    //   this.detail.thirdProductName = ''
    // },
  },
  mounted() {
    // getAction('/admin/dict/code/ticket_type', {}, '/bpi').then(res => {
    //   let { data } = res
    //   this.thirdTypeSearchData = data.map(e => {
    //     return {
    //       name: e.name,
    //       value: e.id
    //     }
    //   })
    // })
    const { id, shopId, scenicId } = this.$route.query
    if (id) {
      this.getDetail(id)
    } else {
      this.detail.linkShopId = shopId
      this.detail.scenicId = scenicId
      this.storeSearch('')
    }
    api.command.getLabelCodeGroup.call(this, {
      url: '/api/base/farmLabelData/getByCodeSelf',
      params: {
        id: id ?? '',
        codes: [
          {
            key: 'scenic_label',
          },
        ],
      },
    })
  },
  methods: {
    getDetail(id) {
      if (id) {
        api.command.getDetail
          .call(this, {
            url: `/goods/farmTicket/detail?id=${id}`,
          })
          .then((res) => {
            this.storeSearch(res.linkShopName)
            this.detail = {
              ...res,
              // realNameFlag: !res.realNameFlag
              //   ? []
              //   : ['0'].includes(res.realNameFlag)
              //   ? []
              //   : res.realNameFlag.split(','),
              refundType:res.refundType || '0',
              realNameInput: res.realNameInput ? res.realNameInput.split(',') : [],
            }
            // if (res.comboFlag == '0') {
            //   this.detail.comboFlag = '1'
            // } else {
            //   this.detail.comboFlag = '0'
            // }
            this.$forceUpdate()
          })
      }
    },
    /** 数据查询 */
    getMainBody(id) {
      getAction(`/api/baseMonitor/queryBaseMonitorList?shopId=${id}`).then((e) => {
        this.isShowSnapshot = e.data.length > 0 ? true : false
      })
    },
    linkShopMenuClick(data) {
      if (this.detail.linkShopId !== data.id) {
        this.detail.productTypeName = ''
        this.detail.productType = ''
      }
    },
    async storeSearch(value = '') {
      const searchResult = await api.command.search.call(this, {
        url: `/api/commodity/farmMainBody/list?mainBodyName=${value}`,
      })
      this.storeData = searchResult.map((el) => {
        return {
          ...el,
          name: el.mainBodyName,
          value: el.id,
        }
      })
      this.detail.linkShopName = this.storeData.filter((e) => e.value === this.detail.linkShopId)[0].name
      this.$forceUpdate()
    },
    async typeSearch(value = '') {
      if (!this.detail.linkShopId) {
        this.$message.warning('请选择关联店铺!')
        return
      }
      const searchResult = await api.command.search.call(this, {
        url: `/api/farmTicketType/list?shopId=${this.detail.linkShopId}&name=${value}`,
      })
      this.typeSearchData = searchResult.map((el) => {
        return {
          ...el,
          name: el.name,
          value: el.id,
        }
      })
    },
    /** end 数据查询 */

    // #region 基础设置
    getFormPz() {
      return {
        title: '配置',
        type: 'cardForm',
        data: [
          {
            name: '加入分销商品',
            type: 'switchOpen',
            cols: 2,
            key: 'joinFx',
            showText: ['开', '关'],
          },
          {
            name: '开放购买（默认选择是）',
            type: 'radioButton',
            key: 'canBuy',
            cols: 4,
            typeData: [
              {
                name: '是',
                value: '1',
              },
              {
                name: '否',
                value: '0',
              },
            ],
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            name: '第三方票务',
            type: 'switchOpen',
            cols: 2,
            key: 'thirdTicket',
            showText: ['开', '关'],
          },
          ...this.tabFormPz(),
          {
            display: true,
            name: '预定说明',
            type: 'textArea',
            key: 'predetermineNote',
            cols: 24,
            maxLength: 200,
            minHeight: '80px',
            showCount: true,
          },
        ],
      }
    },
    tabFormPz() {
      if (this.detail.thirdTicket == '0') {
        return []
      } else {
        return [
          {
            name: '票务平台',
            type: 'select',
            key: 'thirdPlatform',
            cols: 3,
            typeData: [
              {
                name: '自我游',
                value: '0',
              },
              {
                name: '云上游',
                value: '1',
              },
            ],
            // rules: [
            //   {
            //     required: true,
            //   },
            // ],
            onChange: (value) => {
              this.detail.thirdPlatform = value
              this.detail.thirdProductId = ''
              this.detail.thirdProductName = ''
              this.$forceUpdate()
            },
          },
          {
            name: '票务种类',
            type: 'row',
            // rules: [
            //   {
            //     required: true,
            //   },
            // ],
            children: [
              {
                label: '票务种类',
                type: 'input',
                cols: 22,
                key: 'thirdProductName',
                disabled: true,
                placeholder: '请点击后面按钮选择种类',
                rules: [
                  {
                    required: true,
                  },
                ],
              },
              {
                type: 'button',
                cols: 2,
                props: {
                  icon: 'menu',
                  type: 'primary',
                  on: {
                    click: () => {
                      this.showTicketKind()
                    },
                  },
                },
              },
            ],
          },
        ]
      }
    },
    tabFormThird(){
      if (this.detail.thirdTicket == '0') {
        return []
      } else {
        return [
          {
            display: true,
            name: '限购方式',
            type: 'radioButton',
            key: 'purchaseRestrictionType',
            cols: 12,
            typeData: [
              {
                name: '手机号限购',
                value: '0',
              },
              {
                name: '身份证限购',
                value: '1',
              },
            ],
            rules: [
              {
                required: true,
              },
            ],
            onChange: (data) => {
              if (data == '1') {
                this.detail.realNameFlag = '1'
                // this.detail.realNameInput = ['link_man', 'link_phone', 'link_credit_no']
              }
            },
          },
          {
            display: true,
            name: '最大购买数量 (用户ID，“0”表示不限制数量)',
            type: 'inputNumber',
            key: 'buyCount',
            cols: 12,
            props: {
              min: 0,
            },
            rules: [
              {
                required: true,
                type: 'number',
              },
            ],
          },
        ]
      }
    },
    showTicketKind() {
      const that = this
      if (!this.detail.thirdPlatform) {
        this.$message.error('请先选择票务平台')
        return
      }
      apiTool.showModal({
        width: '50vw',
        title: '选择票务种类',
        view: Ticket,
        viewProps: {
          ticketType: this.detail.thirdPlatform,
          productIdArr: [],
        },
        success: ({ data, setHidden }) => {
          const { productNo, productName, productImg } = data
          this.detail.thirdProductId = productNo
          this.detail.thirdProductName = productName
          this.detail.bannerUrl = productImg
          // getAction('/ticket/productInfo', { id }, '/api').then((rs) => {
          //   const { data } = rs
          //   this.productInfo = data
          //   this.detail.bannerUrl = data.img && data.img
          //   this.handleData(data)
          // })
          setHidden()
          setTimeout(() => {
            that.$forceUpdate()
          }, 500)
        },
        foot: () => [],
        // foot: ({ close }) => {
        //   return <Button onClick={close}>关闭</Button>
        // }
      })
    },
    onStoreSearch(name = '') {
      const { scenicId } = this.$route.query
      getAction('/goods/farmProductCombo/getRelationList', {
        comboType: '1',
        businessId: scenicId,
        comboName: name ? name : '',
      }).then((e) => {
        this.detail.productComboName = name
        this.productComboName = e.data.map((e) => ({
          name: e.comboName,
          value: e.id,
          ...e,
        }))
      })
    },
    // onMenuClick(data) {
    //   const { id } = this.$route.query
    //   this.getDetail(data.id, false)
    //   setTimeout(() => {
    //     this.detail.comboName = data.comboName
    //     this.detail.productComboName = data.comboName
    //     this.detail.productComboId = data.id
    //     this.detail.id = id
    //     this.$forceUpdate()
    //   }, 100)
    // },
    getForm1() {
      const { id } = this.$route.query
      return {
        title: '基本信息',
        type: 'cardForm',
        data: [
          {
            name: '是否为套餐',
            type: 'switch',
            cols: 24,
            key: 'comboFlag',
            disabled: id,
            reversal: true,
            // onChange: (state) => {
            //   this.detail.comboFlag = state
            // },
          },
          {
            name: '景点名称(15字符内)',
            type: 'dropDownInput',
            cols: 24,
            key: 'productComboName',
            disabled: id,
            onInputSearch: this.onStoreSearch,
            // onMenuClick: this.onMenuClick,
            valueKey: 'productComboId',
            inputProps: {
              placeholder: '搜索套餐',
              addonBefore: <a-icon type="search" />,
            },
            rules: [
              {
                required: true,
                message: '请选择景点名称',
              },
            ],
            display: this.detail.comboFlag == '1',
          },
          {
            name: '名称',
            type: 'input',
            cols: 12,
            key: 'productName',
            props: {
              maxLength: 76,
            },
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            name: '关联店铺',
            type: 'dropDownInput',
            cols: 12,
            key: 'linkShopName',
            onInputSearch: this.storeSearch,
            onMenuClick: this.linkShopMenuClick,
            disabled: true,
            valueKey: 'linkShopId',
            inputProps: {
              placeholder: '搜索店铺',
              addonBefore: <a-icon type="search" />,
            },
            // rules: [
            //   {
            //     required: true,
            //   },
            // ],
          },
          ...[
            {
              name: '票种',
              type: 'select',
              cols: 12,
              key: 'thirdType',
              rules: [
                {
                  required: true,
                },
              ],
              display: this.detail.thirdTicket == '0' || this.detail.thirdPlatform == '1',
              onChange: (val) => {
                this.detail.thirdTypeName = thirdTypeDataList().find((f) => f.value == val)?.name || ''
                this.$forceUpdate()
              },
            },
            {
              name: '分类',
              type: 'row',
              cols: 12,
              gutter: 16,
              rules: [
                {
                  required: true,
                },
              ],
              children: [
                {
                  label: '分类',
                  type: 'dropDownInput',
                  cols: 20,
                  key: 'productTypeName',
                  onInputSearch: this.typeSearch,
                  valueKey: 'productType',
                  inputProps: {
                    placeholder: '选择分类',
                    addonBefore: <a-icon type="search" />,
                  },
                  rules: [
                    {
                      required: true,
                    },
                  ],
                },
                {
                  type: 'button',
                  cols: 4,
                  label: '管理分类',
                  props: {
                    on: {
                      click: () => {
                        if (this.detail.linkShopName && this.detail.linkShopId) {
                          getAction('/farmTicketType/list', { shopId: this.detail.linkShopId }, '/api').then((res) => {
                            let { data } = res
                            this.classifyList = data
                            this.showManageClassify(data)
                          })
                        } else {
                          this.$message.warning('请先选择关联店铺')
                        }
                      },
                    },
                  },
                },
              ],
              display: true,
            },
            {
              name: '使用人数',
              type: 'inputNumber',
              key: 'hxCount',
              cols: 12,
              display: true,
              rules: [
                {
                  required: true,
                  type: 'number',
                },
              ],
            },
            ...this.tabFormThird(),
            {
              name: '适用人群',
              type: 'rich',
              cols: 24,
              placeholder: '例如：1.2m~1.5m的儿童',
              key: 'scopeDesc',
              display: true,
              maxLength: 1000,
              props: {
                showCount: true,
              },
            },
            
          ].filter((f) => f.display),
        ].filter((e) => (e.display == undefined ? true : e.display)),
      }
    },
    showManageClassify(dataSource) {
      apiTool.showModal({
        width: '800px',
        title: '分类',
        success: ({ data, setHidden }) => {
          try {
            dataSource.forEach((e, i) => {
              if (!e.name || (e.sort !== 0 && !e.sort)) {
                throw new Error('请完善名称/排序！')
              }
            })
          } catch (error) {
            this.$message.warning('请完善名称/排序！')
            return false
          }
          let obj = {
            shopId: this.detail.linkShopId,
            ticketTypeList: dataSource.map((e) => {
              return {
                ...e,
                shopId: this.detail.linkShopId,
              }
            }),
          }
          postAction('/farmTicketType/saveBatch', obj).then((res) => {
            if (res.code == 200) {
              this.$message.success('保存成功！')
              setHidden()
            } else {
              this.$message.error('保存失败！')
            }
          })
        },
        form: [
          {
            type: 'table',
            wrapperCol: { span: 24 },
            showFootButton: true,
            showRowSelect: false,
            showPagination: false,
            // table新增按钮回调
            onAddData: () => {
              return {
                name: null,
                sort: 0,
                productCount: 0,
              }
            },
            columns: [
              {
                dataIndex: 'name',
                align: 'left',
                title: (
                  <div>
                    <span style="color:#f5222d">*</span>名称
                  </div>
                ),
                width: 240,
                customRender: function (text, records, index) {
                  const onInput = (data) => {
                    records.name = data.target.value
                  }
                  return <a-input placeholder="名称" onChange={onInput} value={text} />
                },
              },
              {
                dataIndex: 'sort',
                align: 'left',
                title: (
                  <div>
                    <span style="color:#f5222d">*</span>排序
                  </div>
                ),
                width: 100,
                customRender: function (text, records) {
                  const onInput = (data) => {
                    records.sort = data.target.value
                  }
                  return <a-input type="number" placeholder="" onChange={onInput} value={text} />
                },
              },
              {
                dataIndex: 'productCount',
                align: 'left',
                title: '已关联商品',
                width: 80,
                customRender: function (text, records) {
                  return <div>{text}</div>
                },
              },
              {
                align: 'center',
                width: '10%',
                title: '操作',
                tableType: 'editDelete',
                unShowEditBtn: true,
                disabledKey: 'productCount',
              },
            ],
            dataSource: dataSource,
          },
        ],
      })
    },
    getFormUp() {
      return {
        type: 'tabs',
        key: 'selectType',
        children: [
          {
            title: () => (
              <div>
                <span style="color:#f00">*</span>
                Banner
              </div>
            ),
            data: [
              {
                type: 'iconUpload',
                maxLength: 1,
                cols: 12,
                descTitle: 'Banner',
                typeData: [
                  {
                    maxLength: 1,
                    key: 'bannerUrl',
                    desc: '<br /> 支持格式.png/.jpg',
                  },
                ],
                rules: [
                  {
                    required: true,
                    message: '请上传图片',
                    type: 'string',
                  },
                ],
              },
            ],
          },
          {
            title: '宣传视频',
            data: [
              {
                type: 'iconUploadVideo',
                cols: 12,
                descTitle: '宣传视频',
                typeData: [
                  {
                    maxLength: 1,
                    key: 'videoUrl',
                    desc: '视频上传尺寸<20M <br /> 支持格式.mov/.mp4/.wmv/.avi',
                  },
                ],
              },
            ],
          },
        ],
      }
    },
    getForm2Copy() {
      return {
        type: 'cardForm',
        title: '数字基地监控视频',
        data: [this.isShowSnapshot ? this.getBaseDataInit(this.detail.linkShopId) : {}],
      }
    },
    getBaseDataInit(shopId = null) {
      return {
        type: 'baseUpload',
        key: 'monitorCode',
        maxLength: 1,
        cols: 24,
        shopId: shopId,
        setValue: this.detail.monitorCode,
        setFirm: false,
      }
    },
    onAddRule(index) {
      this.returnSet.push({})
    },
    renderTableList(index) {
      return (
        <div class="data-list-box">
          <div class="item-value">
            <span>有效日期：</span>
            <a-select default-value="lucy" style="width: 175px">
              <a-select-option value="jack">Jack</a-select-option>
              <a-select-option value="lucy">Lucy</a-select-option>
            </a-select>
            <a-select default-value="lucy" style="width: 130px;margin-left:15px">
              <a-select-option value="jack">Jack</a-select-option>
              <a-select-option value="lucy">Lucy</a-select-option>
            </a-select>
            <span>&nbsp;&nbsp;可退款&nbsp;&nbsp;</span>
            <a-select default-value="lucy" style="width: 215px">
              <a-select-option value="jack">Jack</a-select-option>
              <a-select-option value="lucy">Lucy</a-select-option>
            </a-select>
            <a-input default-value="lucy" style="width: 88px;margin-left:15px" />
            <a-button type="danger" ghost style="margin-left: 15px; width:100px">
              删除
            </a-button>
          </div>
          <a-button type="primary" style="margin-top: 15px" onClick={() => this.onAddRule(index)}>
            添加退款规则
          </a-button>
        </div>
      )
    },
    getFormTksz() {
      let that = this
      return {
        title: '退款设置',
        type: 'cardForm',
        data: [
          {
            display: true,
            name: '退款方式',
            type: 'radioButton',
            key: 'refundExplain',
            cols: 12,
            typeData: [
              {
                name: '随时退',
                value: '0',
              },
              {
                name: '不支持退款',
                value: '1',
              },
              {
                name: '有效期内可退',
                value: '2',
              },
            ],
            rules: [
              {
                required: true,
              },
            ],
          },
        ].filter((e) => e.display),
      }
    },
    getFormTk() {
      let that = this
      return {
        title: '订单退款确认方式',
        type: 'cardForm',
        data: [
          {
            display: true,
            name: '退款确认方式',
            type: 'radioButton',
            key: 'refundType',
            cols: 12,
            typeData: [
              {
                name: '人工审核退款',
                value: '0',
              },
              {
                name: '自动处理退款',
                value: '1',
              },
            ],
            rules: [
              {
                required: true,
              },
            ],
          },
        ].filter((e) => e.display),
      }
    },
    getFormRules() {
      let that = this
      return {
        title: '退款规则',
        type: 'cardForm',
        data: [
          {
            display: this.detail.refundExplain == '0',
            type: 'radioButton',
            key: 'refundWay',
            style: {
              paddingLeft: '20px',
            },
            cols: 24,
            typeData: [
              {
                name: '手动退',
                value: '0',
              },
              {
                name: '过期自动退',
                value: '1',
              },
            ],
          },
          {
            display:
              that.detail.refundExplain == '2' || (that.detail.refundExplain == '0' && that.detail.refundWay == '0'),
            cols: 24,
            type: 'table',
            showFootButton: true,
            showRowSelect: false,
            showPagination: false,
            // table新增按钮回调
            onAddData: () => {
              return {
                validDay: '1',
                validDate: '6',
                refundType: '0',
                refundExplain: 0,
              }
            },
            columns: [
              {
                dataIndex: 'validDay',
                align: 'left',
                width: '22%',
                title: (
                  <div>
                    <span style="color:#f5222d">*</span>有效日期
                  </div>
                ),
                customRender: function (text, records, index) {
                  const onChange = (data) => {
                    records.validDay = data
                  }
                  return (
                    <a-select onChange={onChange} style={{ width: '100%' }} placeholder="有效日期" defaultValue={text}>
                      {that.detail.refundExplain == '0' &&
                        daysBeforeList.map((e) => {
                          return <a-select-option value={e.value}>{e.name}</a-select-option>
                        })}
                      {that.detail.refundExplain !== '0' &&
                        daysAfterList.map((e) => {
                          return <a-select-option value={e.value}>{e.name}</a-select-option>
                        })}
                    </a-select>
                  )
                },
              },
              {
                dataIndex: 'validDate',
                align: 'left',
                width: '22%',
                title: (
                  <div>
                    <span style="color:#f5222d">*</span>有效时间
                  </div>
                ),
                customRender: function (text, records, index) {
                  const onChange = (data) => {
                    records.validDate = data
                  }
                  return (
                    <a-select onChange={onChange} style={{ width: '100%' }} placeholder="有效时间" defaultValue={text}>
                      {timeAfterList.map((e) => {
                        return <a-select-option value={e.value}>{e.name}</a-select-option>
                      })}
                    </a-select>
                  )
                },
              },
              {
                dataIndex: 'refundType',
                align: 'left',
                title: (
                  <div>
                    <span style="color:#f5222d">*</span>退款类别
                  </div>
                ),
                width: '22%',
                customRender: function (text, records, index) {
                  const onChange = (data) => {
                    records.refundType = data
                    records.refundExplain = 0
                    that.$forceUpdate()
                  }
                  return (
                    <a-select onChange={onChange} style={{ width: '100%' }} placeholder="退款类别" defaultValue={text}>
                      {ticketRefundType.map((e) => {
                        return <a-select-option value={e.value}>{e.name}</a-select-option>
                      })}
                    </a-select>
                  )
                },
              },
              {
                dataIndex: 'refundExplain',
                align: 'left',
                width: '22%',
                title: (
                  <div>
                    <span style="color:#f5222d">*</span>扣款金额/系数
                  </div>
                ),
                customRender: function (text, records, index) {
                  const onInput = (data) => {
                    records.refundExplain = data.target.value && Number(data.target.value)
                  }
                  const onChange = (data) => {
                    records.refundExplain = data

                    that.$forceUpdate()
                  }
                  if (records.refundType == '1') {
                    return (
                      <a-select
                        onChange={onChange}
                        style={{ width: '100%' }}
                        placeholder="扣款金额/系数"
                        defaultValue={text}
                      >
                        {ticketRefundRatioList.map((e) => {
                          return <a-select-option value={e.value}>{e.name}</a-select-option>
                        })}
                      </a-select>
                    )
                  }
                  return <a-input type="number" placeholder="扣款金额/系数" onChange={onInput} value={text} />
                },
              },
              {
                dataIndex: 'a10',
                align: 'center',
                width: '12%',
                title: '操作',
                unShowEditBtn: true,
                tableType: 'editDelete',
                onDel: (text, records, index) => {
                  if (that.detail.ticketRefundList.length > 1) {
                    that.detail.ticketRefundList.splice(index, 1)
                  } else {
                    this.$message.error('退款规则最少保留一条规则！')
                  }
                },
              },
            ],
            dataSource: that.detail.ticketRefundList,
          },
        ].filter((e) => e.display),
      }
    },
    radioFormTkfs() {
      if (this.detail.refundExplain == '0' && this.detail.refundWay == '1') {
        return []
      } else {
        return [
          {
            display: true,
            type: 'row',
            cols: 24,
            gutter: 6,
            children: [
              {
                display: true,
                type: 'text',
                value: '有效日期:',
                cols: 3,
              },
              {
                display: true,
                label: '',
                type: 'select',
                key: 'refundWay',
                cols: 4,
                typeData: this.detail.refundExplain == '0' ? daysBeforeList : daysAfterList,
              },
              {
                display: true,
                label: '',
                type: 'select',
                key: 'refundWay',
                cols: 3,
                typeData: timeAfterList,
              },
              {
                display: true,
                type: 'text',
                value: '可退款',
                cols: 2,
              },
              {
                display: true,
                label: '',
                type: 'select',
                key: 'refundWay',
                cols: 8,
                typeData: ticketRefundType,
              },
              {
                display: true,
                label: '',
                type: 'inputNumber',
                key: 'refundWay',
                cols: 3,
                props: {
                  min: 0,
                },
              },
            ],
          },
        ]
      }
    },
    // #endregion 基础设置
    renderDisabled() {
      let val = this.detail.thirdTicket == '1' ? true : this.detail.canBuy == '0' ? true : false
      return val
    },
    // #region 销售设置
    getForm3() {
      let that = this
      return {
        type: 'table',
        showFootButton: false,
        showRowSelect: false,
        showPagination: false,
        // table新增按钮回调
        onAddData: () => {
          return {
            skuImgUrl: '',
            skuName: null,
            originalPrice: null,
            salePrice: null,
            jsPrice: null,
            allStock: null,
            sort: null,
          }
        },
        columns: [
          {
            title: '图片',
            type: 'lt-40',
            dataIndex: 'skuImgUrl',
            customRender: (text, records, index, h) => {
              return (
                <FormUploadSku
                  value={text}
                  disabled={that.renderDisabled()}
                  onChange={(data) => {
                    records.skuImgUrl = data
                  }}
                />
              )
            },
          },
          {
            dataIndex: 'skuName',
            align: 'left',
            title: (
              <div>
                <span style="color:#f5222d">*</span>产品规格
              </div>
            ),
            width: 200,
            customRender: function (text, records, index) {
              const onInput = (data) => {
                records.skuName = data.target.value
              }
              return <a-input disabled={that.renderDisabled()} placeholder="产品规格" onChange={onInput} value={text} />
            },
          },
          {
            dataIndex: 'originalPrice',
            align: 'left',
            title: '原价',
            width: 100,
            customRender: function (text, records) {
              const onInput = (data) => {
                records.originalPrice = data.target.value
              }
              return (
                <a-input
                  disabled={that.renderDisabled()}
                  type="number"
                  addon-before="￥"
                  placeholder="价格"
                  onChange={onInput}
                  value={text}
                />
              )
            },
          },
          {
            dataIndex: 'salePrice',
            align: 'left',
            title: (
              <div>
                <span style="color:#f5222d">*</span>销售价
              </div>
            ),
            width: 100,
            customRender: function (text, records) {
              const onInput = (data) => {
                records.salePrice = data.target.value
              }
              return (
                <a-input
                  disabled={that.renderDisabled()}
                  type="number"
                  addon-before="￥"
                  placeholder="销售价"
                  onChange={onInput}
                  value={text}
                />
              )
            },
          },
          {
            dataIndex: 'jsPrice',
            align: 'left',
            title: (
              <div>
                <span style="color:#f5222d">*</span>商家结算价
              </div>
            ),
            width: 100,
            customRender: function (text, records) {
              const onInput = (data) => {
                records.jsPrice = data.target.value
              }
              return (
                <a-input
                  disabled={that.renderDisabled()}
                  type="number"
                  addon-before="￥"
                  placeholder="价格"
                  onChange={onInput}
                  value={text}
                />
              )
            },
          },
          {
            dataIndex: 'allStock',
            align: 'left',
            title: (
              <div>
                <span style="color:#f5222d">*</span>库存
              </div>
            ),
            width: 100,
            customRender: function (text, records, index) {
              const onInput = (data) => {
                records.allStock = data.target.value
              }
              return (
                <a-input
                  disabled={that.renderDisabled()}
                  type="number"
                  placeholder="库存数量"
                  onChange={onInput}
                  value={text}
                />
              )
            },
          },
          //   {
          //     dataIndex: 'sort',
          //     align: 'left',
          //     title: (
          //       <div>
          //         <span style="color:#f5222d">*</span>排序
          //       </div>
          //     ),
          //     width: 100,
          //     customRender: function(text, records, index) {
          //       const onInput = data => {
          //         records.sort = data.target.value
          //       }
          //       return records.isEdit ? (
          //         <a-input
          //           disabled={that.detail.canBuy == '0'}
          //           type="number"
          //           placeholder="排序"
          //           onChange={onInput}
          //           value={text}
          //         />
          //       ) : (
          //         <div>{text}</div>
          //       )
          //     }
          //   }
          //   {
          //     align: 'center',
          //     width: '10%',
          //     title: '操作',
          //     tableType: 'editDelete',
          //     unShowEditBtn: true
          //   }
        ],
        dataSource: that.detail.skuList,
      }
    },
    getFormFysm() {
      return {
        title: '费用说明',
        type: 'cardForm',
        data: [
          {
            name: '费用包含',
            type: 'input',
            key: 'expenseContain',
            cols: 24,
            maxLength: 100,
            showCount: true,
          },
        ],
      }
    },
    getFormXnsj() {
      return {
        title: '虚拟数据',
        type: 'cardForm',
        data: [
          {
            name: '基础销量',
            type: 'inputNumber',
            key: 'saleCount',
            cols: 6,
            props: {
              min: 0,
            },
          },
          {
            name: '基础浏览量',
            type: 'inputNumber',
            key: 'readCount',
            cols: 6,
            props: {
              min: 0,
            },
          },
          //   {
          //     name: '限购数量',
          //     type: 'inputNumber',
          //     key: 'buyCount',
          //     cols: 6,
          //     props: {
          //       min: 0
          //     }
          //   }
        ],
      }
    },
    // #endregion 销售设置

    // #region 高级设置
    getFormXssz() {
      return {
        title: '销售设置',
        type: 'cardForm',
        data: [
          {
            display: true,
            name: '支付方式',
            type: 'radioButton',
            key: 'payType',
            cols: 12,
            typeData: [
              {
                name: '在线支付',
                value: '0',
              },
            ],
            rules: [
              {
                required: true,
              },
            ],
          },
          {
            display: true,
            name: '限购方式',
            type: 'radioButton',
            key: 'purchaseRestrictionType',
            cols: 12,
            typeData: [
              {
                name: '手机号限购',
                value: '0',
              },
              {
                name: '身份证限购',
                value: '1',
              },
            ],
            rules: [
              {
                required: true,
              },
            ],
            onChange: (data) => {
              if (data == '1') {
                this.detail.realNameFlag = '1'
                this.detail.realNameInput = ['link_man', 'link_phone', 'link_credit_no']
              }
            },
          },
          {
            display: true,
            name: '最大购买数量 (用户ID，“0”表示不限制数量)',
            type: 'inputNumber',
            key: 'buyCount',
            cols: 12,
            props: {
              min: 0,
            },
            rules: [
              {
                required: true,
                type: 'number',
              },
            ],
          },
          {
            name: '使用日期(新的设置适用于新产生的订单；对于已经产生的订单，仍需按原有的核销时间为用户提供服务)',
            type: 'radio',
            key: 'hxExpireType',
            cols: 12,
            typeData: [
              {
                name: () => {
                  const onInput = (data) => {
                    this.detail.hxAfterDay = data.target.value
                  }
                  return (
                    <span style="margin-right: 100px">
                      必须在购买后&nbsp;
                      <a-input
                        style={{ width: '70px' }}
                        type="number"
                        min={0}
                        placeholder=""
                        onChange={onInput}
                        value={this.detail.hxAfterDay}
                      />
                      &nbsp;天内使用
                    </span>
                  )
                },
                value: '1',
              },
              {
                name: () => {
                  return <span style="margin-right: 100px">在指定购买日期使用</span>
                },
                value: '0',
              },
              {
                style: {
                  marginTop: '10px',
                },
                name: () => {
                  return (
                    <span style={{ marginTop: '30px' }}>
                      在购买后&nbsp;
                      <DatePicker.RangePicker
                        showTime={false}
                        ranges={{
                          最近一周: [moment(), moment().add(1, 'weeks')],
                          最近一个月: [moment(), moment().add(1, 'month')],
                          最近三个月: [moment(), moment().add(3, 'month')],
                        }}
                        allowClear={false}
                        onChange={this.onTimeChange}
                        value={this.getData()}
                        style={{ width: '400px' }}
                        format="YYYY-MM-DD"
                        placeholder={['开始日期', '结束日期']}
                      ></DatePicker.RangePicker>
                      &nbsp;时间段内都可使用
                    </span>
                  )
                },
                value: '5',
              },
            ],
          },
        ],
      }
    },
    getFormYdxz() {
      return {
        title: '预定设置',
        type: 'cardForm',
        data: [
          {
            display: true,
            name: '最大可预定天数',
            type: 'row',
            cols: 12,
            gutter: 6,
            children: [
              {
                type: 'text',
                value: '在开售时间起提前',
                cols: 4,
              },
              {
                label: '',
                type: 'inputNumber',
                key: 'preDay',
                cols: 3,
                props: {
                  min: 0,
                },
              },
              {
                type: 'text',
                value: '天可提前预定',
                cols: 4,
              },
            ],
          },
          // {
          //   display: true,
          //   name: '预定时间限制',
          //   type: 'row',
          //   cols: 12,
          //   gutter: 6,
          //   children: [
          //     {
          //       type: 'text',
          //       value: '在提前天数当日要求在',
          //       cols: 5,
          //     },
          //     {
          //       type: 'timePicker',
          //       cols: 4,
          //       key: 'preTime',
          //       props: {
          //         format: 'HH:mm',
          //         valueFormat: 'HH:mm',
          //         allowClear: false,
          //       },
          //     },
          //     {
          //       type: 'text',
          //       value: '之前预定',
          //       cols: 4,
          //     },
          //   ],
          // },
          {
            display: true,
            name: '生效时间限制',
            type: 'row',
            cols: 12,
            gutter: 6,
            children: [
              {
                type: 'text',
                value: '必须在购买后',
                cols: 3,
              },
              {
                label: '',
                type: 'inputNumber',
                key: 'useAfterDay',
                cols: 3,
                props: {
                  min: 0,
                },
              },
              {
                type: 'text',
                value: '天，',
                cols: 1,
              },
              {
                label: '',
                type: 'inputNumber',
                key: 'useAfterHour',
                cols: 3,
                props: {
                  min: 0,
                  max: 23,
                },
              },
              {
                type: 'text',
                value: '小时，才可使用',
                cols: 4,
              },
            ],
          },
          // {
          //   display: true,
          //   name: '用户信息',
          //   type: 'checkBoxButton',
          //   key: 'realNameFlag',
          //   cols: 12,
          //   typeData: [
          //     {
          //       name: '实名制',
          //       value: '1',
          //     },
          //   ],
          // },
          {
            display: this.detail.realNameFlag == '1',
            name: '下单时填写信息',
            type: 'checkBoxButton',
            key: 'realNameInput',
            cols: 12,
            disabled: this.detail.purchaseRestrictionType == '1',
            typeData: [
              {
                name: '姓名',
                value: 'link_man',
              },
              {
                name: '电话',
                value: 'link_phone',
              },
              {
                name: '身份证',
                value: 'link_credit_no',
              },
            ],
            rules: [
              {
                required: true,
                type: 'array',
                message: '请最少选择一种',
              },
            ],
          },
          {
            display: this.detail.realNameFlag?.length > 0,
            name: '用户信息数量',
            type: 'radioButton',
            key: 'userInfoCount',
            cols: 12,
            typeData: [
              {
                name: '只填写一个人信息',
                value: 0,
              },
              {
                name: '根据下单数量填写所有用户信息',
                value: 1,
              },
            ],
            rules: [
              {
                required: true,
                type: 'number',
              },
            ],
          },
          {
            display: true,
            name: '预定成功发送',
            type: 'radioButton',
            key: 'unSend',
            cols: 12,
            typeData: [
              {
                name: '不发送',
                value: '0',
              },
              {
                name: '发送短信',
                value: '1',
              },
            ],
          },
        ].filter((e) => e.display),
      }
    },
    // 订单确认方式
    getFormDdqrfs() {
      return {
        title: '订单确认方式',
        type: 'cardForm',
        data: [
          {
            display: true,
            name: '确认方式',
            type: 'radioButton',
            key: 'confirmMethod',
            cols: 12,
            typeData: [
              {
                name: '自动确认(库存为0时自动转变成人工确认)',
                value: '0',
              },
              {
                name: '人工确认',
                value: '1',
              },
            ],
          },
        ],
      }
    },
    getFormSysm() {
      return {
        title: '使用说明',
        type: 'cardForm',
        data: [
          // {
          //   display: this.detail.changeTicket == 0,
          //   type: 'text',
          //   cols: 12,
          // },
          {
            display: this.detail.changeTicket == 1,
            name: '换票地址',
            type: 'input',
            key: 'changeTicketAddress',
            cols: 12,
          },
          {
            display: true,
            name: '说明',
            type: 'textArea',
            key: 'changeTicketRemark',
            cols: 24,
            maxLength: 1000,
            showCount: true,
          },
          {
            display: true,
            name: '使用地址',
            type: 'row',
            cols: 12,
            gutter: 10,
            children: [
              {
                label: '使用地址',
                type: 'input',
                key: 'useAddress',
                cols: 20,
              },
              {
                type: 'button',
                cols: 4,
                label: '填入的店铺地址',
                props: {
                  style: {
                    color: '#53aff4',
                    border: '1px solid #53aff4',
                  },
                  on: {
                    click: () => {
                      let addr = this.storeData.find((f) => f.id == this.detail.linkShopId)?.address || ''
                      if (addr) {
                        this.detail.useAddress = addr
                        this.$forceUpdate()
                      } else {
                        this.$message.warning('当前店铺暂无地址！')
                      }
                    },
                  },
                },
              },
            ],
          },
          {
            display: true,
            name: '核销模式',
            type: 'radioButton',
            key: 'hxMode',
            cols: 4,
            onChange: (value) => {
              if (value == '1') {
                this.detail.qrShowMode = '0'
              }
            },
            typeData: [
              {
                name: '1码1人',
                value: '0',
              },
              {
                name: '1码多人',
                value: '1',
              },
            ],
          },
          {
            display: true,
            name: '门票码展示',
            type: 'radioButton',
            key: 'qrShowMode',
            cols: 4,
            disabled: this.detail.hxMode == '1',
            typeData: [
              {
                name: '单码',
                value: '0',
              },
              {
                name: '多码',
                value: '1',
              },
            ],
          },
          {
            display: true,
            name: '入园设置',
            type: 'radioButton',
            key: 'changeTicket',
            cols: 4,
            typeData: [
              {
                name: '无需换票',
                value: '0',
              },
              {
                name: '换票使用',
                value: '1',
              },
            ],
          },
        ].filter((e) => e.display),
      }
    },
    // #endregion 高级设置

    /** 方法 */
    handleData(info) {
      const { productName, img, marketPrice, salePrice, settlementPrice, chargeInclude, isSingle, userNote } = info
      this.detail = {
        ...this.detail,
        expenseContain: chargeInclude,
        userInfoCount: isSingle,
        changeTicketRemark: userNote,
        skuList: [
          {
            skuImgUrl: img,
            skuName: productName,
            originalPrice: marketPrice,
            salePrice: salePrice,
            jsPrice: settlementPrice,
            allStock: null,
          },
        ],
      }
      this.$forceUpdate()
    },
    getData() {
      return [this.detail.hxStartTime, this.detail.hxEndTime]
    },
    onTimeChange(times) {
      if (times.length > 0) {
        this.detail.hxStartTime = moment(times[0]).format('YYYY-MM-DD 00:00:00')
        this.detail.hxEndTime = moment(times[1]).format('YYYY-MM-DD 00:00:00')
      }
    },
    getString(str) {
      return str
        ? str
            .split(',')
            .filter((e) => e)
            .join(',')
        : ''
    },
    /** end 方法 */

    getFoot() {
      let that = this
      const left = [
        {
          name: '上一步',
          display: this.activeKey != 0 && this.detail.thirdTicket != 1,
          onClick: (data) => (this.activeKey = this.activeKey - 1),
        },
        {
          name: '提交',
          type: 'primary',
          loading: this.loading,
          sumbit: true,
          display: true,
          onClick: (data) => {
            // 获取标签
            let arr = []
            this.labelGroup.forEach((item) =>
              item.list.forEach((e) => {
                e['labelTypeCode'] = item.labelTypeCode
                e['labelTypeId'] = item.id
                e['labelTypeName'] = item.labelTypeName
              })
            )
            console.log('data', data)
            if (data.switchOpen) {
              if (!data.thirdPlatform) {
                message.error('请选择票务平台')
                return
              }
              if (!data.thirdProductName) {
                message.error('请选择票务种类')
                return
              }
            }
            this.labelGroup.map((e) => arr.push(...e.list))
            let brr = arr.map((e) => ({
              ...e,
              czState: e.czState ? 1 : 0,
            }))
            //  this.detail.comboFlag 0 关 1 开
            if (this.detail.comboFlag == '0') {
              if (this.detail.thirdTicket == '0') {
                if (this.detail.hxExpireType == '1') {
                  if (!this.detail.hxAfterDay) {
                    this.$message.warning('销售设置，使用日期必填')
                    return false
                  }
                }
                if (this.detail.hxExpireType == '5') {
                  if (this.detail.hxStartTime && this.detail.hxEndTime) {
                    if (!moment(this.detail.hxStartTime).isBefore(this.detail.hxEndTime)) {
                      this.$message.warning('日期开始时间不能小于日期结束时间!')
                      return false
                    }
                  } else {
                    this.$message.warning('销售设置，使用日期必填')
                    return false
                  }
                }
              }
            }

            let newTicketRefundList = this.detail.ticketRefundList.map((e) => {
              return {
                ...e,
                validDayName:
                  this.detail.refundExplain == '0'
                    ? daysBeforeList.find(({ value }) => value === e.validDay).name
                    : daysAfterList.find(({ value }) => value === e.validDay).name,
                validDateName: timeAfterList.find(({ value }) => value === e.validDate).name,
                refundExplain: e.refundExplain ? e.refundExplain : 0,
              }
            })
            let monitorCode = null
            let monitorImg = null
            if (data.monitorCode instanceof Array && data.monitorCode.length > 0) {
              monitorCode = (
                data.monitorCode.find((e) => e.select) || {
                  monitorCode: '',
                }
              ).monitorCode
              monitorImg = (
                data.monitorCode.find((e) => e.select) || {
                  monitorImg: '',
                }
              ).monitorImg
            }
            let upDown = data.id ? data.upDown : '0'
            let obj = Object.assign({
              ...data,
              // comboFlag: data.comboFlag == '0' ? '1' : '0',
              upDown,
              monitorCode,
              monitorImg,
              realNameFlag: (data.realNameFlag || []).toString(),
              realNameInput: data.realNameInput && data.realNameInput.length > 0 ? data.realNameInput.join(',') : '',
              farmLabelDataDTOS: brr,
              ticketRefundList: newTicketRefundList,
            })
            console.log('obj', obj)
            // return
            api.command[data.id ? 'edit' : 'create']
              .call(this, {
                url: '/goods/farmTicket',
                params: obj,
                isPost: false,
              })
              .then((result) => {
                setTimeout(() => {
                  this.$router.back()
                }, 500)
              })
          },
        },
        {
          name: '下一步',
          display: this.activeKey != 2 && this.detail.thirdTicket != 1 && this.detail.comboFlag == '0',
          onClick: (data) => {
            let nData = Object.assign({}, data, this.detail)
            if (this.activeKey == 0) {
              if (nData.thirdTicket == 0) {
                if (
                  !(nData.productName && nData.linkShopId && nData.thirdType && nData.productType && nData.bannerUrl)
                ) {
                  this.$message.warning('请完成表单填写')
                  return
                }
              } else if (nData.thirdTicket == 1) {
                if (
                  !(
                    nData.productName &&
                    nData.linkShopId &&
                    nData.thirdProductId &&
                    nData.productType &&
                    nData.bannerUrl
                  )
                ) {
                  this.$message.warning('请完成表单填写')
                  return
                }
              }
            }
            this.activeKey = this.activeKey + 1
          },
        },
      ].filter((e) => e.display)
      const right = [
        {
          name: '取消',
          type: 'default',
          onClick: () => this.$router.back(),
          display: true,
        },
        {
          name: ` ${this.detail.upDown == 0 ? '下架' : '上架'} `,
          type: 'default',
          onClick: () => {
            putAction(`/goods/farmTicket/${this.detail.upDown == 0 ? 'down' : 'up'}?id=${this.detail.id}`).then((e) => {
              if (e.code == 200) {
                this.$message.success(`${this.detail.upDown == 0 ? '下架成功' : '上架成功'}`)
                setTimeout(() => {
                  this.$router.back()
                }, 500)
              } else {
                this.$message.error(e.msg)
              }
            })
          },
          display: this.detail.id,
        },
        {
          name: '删除',
          popTitle: '确认删除吗?',
          isPop: true,
          type: 'danger',
          ghost: true,
          onClick: () => {
            api.command.del.call(this, {
              url: `/goods/farmTicket?id=${this.detail.id}`,
              isPost: false,
            })

            setTimeout(() => {
              this.$router.back()
            }, 500)
          },
          display: this.detail.id,
        },
      ].filter((e) => e.display)
      return {
        left,
        right,
      }
    },
    getFormDescriptionFees() {
      return {
        title: '费用说明',
        type: 'cardForm',
        data: [
          {
            type: 'input',
            name: '费用包含',
            cols: 24,
            key: '',
            maxLength: 200,
          },
        ],
      }
    },
    renderTab() {
      if (this.activeKey === 0) {
        return [
          this.getFormPz(),
          this.getForm1(),
          this.getFormUp(),
          this.isShowSnapshot && this.getForm2Copy(),
          // 费用说明
          this.detail.thirdTicket == '1' && this.getFormFysm(),
          // this.detail.thirdTicket == '1' && this.getFormXnsj(),
        ]
      } else if (this.activeKey === 1) {
        return [this.getFormXssz(), this.getFormYdxz(), this.getFormDdqrfs()]
      } else if (this.activeKey === 2) {
        return [
          this.getFormTksz(),
          // this.getFormTk(),
          this.detail.refundExplain !== '1' && this.getFormRules(),
          this.getFormSysm(),
          this.getFormFysm(),
          this.getFormXnsj(),
        ]
      }
    },
    getTabs() {
      if (this.detail.comboFlag == '0') {
        return ['基础设置', '销售设置', '高级设置']
      } else {
        return ['基础设置']
      }
    },
  },
  render() {
    return (
      <div>
        {this.detail.thirdTicket == '0' && (
          <Naves
            navData={this.getTabs()}
            onActive={(index) => {
              if (
                !(
                  this.detail.productName &&
                  this.detail.linkShopId &&
                  this.detail.thirdType &&
                  this.detail.productType &&
                  this.detail.bannerUrl
                )
              ) {
                this.activeKey = 0
                this.$message.warning('请完成表单填写')
                return
              } else {
                this.activeKey = index
              }
              // this.activeKey = index
            }}
            activeCurrentKey={this.activeKey}
          />
        )}

        <DetailFormGroup
          foot={this.getFoot()}
          form={this.detail}
          typeData={{
            linkShopName: this.storeData,
            productTypeName: this.typeSearchData,
            thirdType: thirdTypeDataList(),
            productComboName: this.productComboName,
          }}
          data={this.renderTab()}
        />
      </div>
    )
  },
}
</script>
<style lang="less" scoped>
/deep/ .detail-form-group {
  height: calc(100vh - 230px);
}

.entityInformation-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}

/deep/.ant-input[type='number'] {
  -moz-appearance: textfield;
}
/deep/.ant-input[type='number']::-webkit-inner-spin-button,
/deep/.ant-input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.boxes {
  .data-list-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  &-two {
    height: 82px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    border-top: 1px solid #e8eaec;
    padding-top: 15px;
    box-sizing: border-box;

    input {
      width: 40%;
    }
  }
  &-one {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    min-height: 100px;
    padding-top: 15px;
    padding-bottom: 15px;

    &:nth-of-type(2) {
      border-top: 1px solid #e8eaec;
      box-sizing: border-box;
    }

    .con {
      width: 58%;
    }

    &-checkbox {
      width: 40%;

      & > :nth-child(1) {
        border: 1px solid #e8eaec;
        padding: 5px 10px;
        box-sizing: border-box;
      }
    }
  }
}
</style>
